<template>
  <BOverlay
    :show="overlay"
    spinner-variant="primary"
    variant="semi-dark"
    v-if="$can('read', 'libros')"
  >
    <BCard>
      <BRow v-if="carga_alert === false">
        <BCol
          lg="2"
          md="3"
          sm="3"
          class="my-1"
        >
          <!-- BOTON MOSTRAR -->
          <btnMostrar
            :pageOptions.sync="pageOptions"
            :perPage.sync="perPage"
            :total.sync="items.length"
          />
        </BCol>
        <BCol
          lg="10"
          md="9"
          sm="9"
          class="my-1"
        >
          <!-- FILTRO -->
          <inputFiltro
            :filter.sync="filter"
          />
        </BCol>
        <!--
        <BCol
          md="4"
          sm="4"
          class="my-1"
        >
          <!-- BOTON CREAR -->
          <!-- <btnCrear
            texto="Libro"
            modulo="libros"
            @processAdd="addLibro"
          />
        </BCol>
        -->

        <BCol cols="12">
          <BTable
            striped
            small
            hover
            noCollapse
            class="mt-1"
            responsive
            :per-page="perPage"
            :current-page="currentPage"
            :items="items"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :busy="cargando"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
          >
            <!-- Cargando -->
            <template #table-busy>
              <div class="text-center text-danger my-2">
                <spinner />
              </div>
            </template>

            <!-- Column: Libro -->
            <!-- <template #cell(tipoEnseñanza)="data">
              <colNombreImg
                :mostrarImg="false"
                :imagen="data.item.insignia"
                :nombre="'1ero Básico '+data.item.letra"
                :txtMuted="'1ero Básico '+data.item.letra"
                :nombreModal="null"
              />
            </template>-->

            <!-- COLUMNA PERIODO -->
            <template #cell(cursos)="data">
              {{ data.item.nombre }} {{ data.item.letra }}
              <!-- <colLibro
                modulo="libros"
                :data="data.item"
                @processUpdateLibro="updateLibro"
              /> -->
            </template>

            <!-- COLUMNA ESTADO -->
            <!-- <template #cell(estado)="data">
              <colEstado
                :data="data"
                modulo="libros"
                @processUpdateEstado="updateEstado"
              />
            </template> -->

            <!-- Column: Action -->
            <template #cell(acciones)="data">
              <col-acciones-libro
                modulo="libros"
                :modal="`modal-lg-${data.item.id}`"
                :data="data"
                @processGoToOpen="goToOpen"
              />
              <!--
                @processGoToConfig="goToConfig"
                @processRemove="remove(data.item)"
                @processGoToClone="goToClone"
              -->
            </template>
          </BTable>
        </BCol>

        <BCol
          cols="12"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="center"
            size="sm"
            class="my-0"
          />
        </BCol>

      </BRow>
      <BRow v-else>
        <BCol cols="12">
          <b-alert
            variant="primary"
            show
            class="text-center mt-25 mr-25 ml-25 pb-2 pt-1"
          >
            La cuenta ingresada no posee horarios asignados.
            Por favor, agregue su carga horaria en "Horarios".
          </b-alert>
        </BCol>
      </BRow>
    </BCard>
  </BOverlay>
</template>

<script>

// ETIQUETAS
import {
  BTable, BRow, BCol, BPagination, BFormCheckbox, BOverlay, BCard, BAlert
} from 'bootstrap-vue'
import { mapGetters, mapActions, mapMutations, mapState } from 'vuex'

// COMPONENTES RECICLADOS
// import colLibro from '../components/List/colLibro.vue'
import btnMostrar from '../components/List/btnMostrar.vue'
import inputFiltro from '../components/List/inputFiltro.vue'
import btnCrear from '../components/List/btnCrear.vue'
import spinner from '../components/spinner.vue'
import colNombreImg from '../components/List/colNombreImg.vue'
import colEstado from '../components/List/colEstado.vue'
import colAccionesLibro from '../components/List/colAccionesLibro.vue'

export default {
  components: {
    BTable,
    BRow,
    BCol,
    BPagination,
    BFormCheckbox,
    BOverlay,
    BCard,
    BAlert,

    colAccionesLibro,
    btnCrear,
    inputFiltro,
    btnMostrar,
    // colLibro,
    colEstado,
    spinner,
    colNombreImg,
  },
  data() {
    return {
      cargando: true,
      carga_alert: false,
      overlay: false,
      // chk
      items: [],

      perPage: 10,
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: '',
      filterOn: [],
      pageOptions: [10, 25, 50],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        // {
        //   key: 'tipoEnseñanza',
        //   label: 'Tipo Enseñanza',
        //   sortable: true,
        //   thStyle: {
        //     width: '200px !important',
        //     display: 'table-cell',
        //     'vertical-align': 'middle',
        //   },
        // },
        {
          key: 'cursos',
          label: 'Cursos',
          sortable: true,
          thStyle: {
            width: '200px !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        // {
        //   key: 'estado',
        //   label: 'Estado',
        //   sortable: true,
        //   tdClass: 'text-center',
        //   thStyle: {
        //     'text-align': 'center',
        //     width: '100px !important',
        //     display: 'table-cell',
        //     'vertical-align': 'middle',
        //   },
        // },
      ],
      fieldAcciones: [

        {
          key: 'acciones',
          label: 'acciones',
          tdClass: 'text-center',
          thStyle: {
            width: '80px !important',
            'text-align': 'center',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
      ],
    }
  },
  computed: {
    ...mapState('auth', ['user']),
    ...mapGetters({ getCursosPersonaRol: 'cursos/getCursosPersonaRol' }),
  },
  watch: {
    getCursosPersonaRol(val) {
      this.totalRows = val.length
      this.items = []
      this.items = this.getCursosPersonaRol
      this.carga_alert = this.items.length === 0
      this.cargando = false
    },
  },
  mounted() {
    this.cargarLibros()

  },
  methods: {
    ...mapActions({
      fetchCursosPersonaRol: 'cursos/fetchCursosPersonaRol',
      updateLibroLibro: 'libros/updateLibro',
      removeLibros: 'libros/removeLibros',
    }),
    ...mapMutations('libros', ['setLibro']),
    setTableList() {
      this.fields.push(this.fieldAcciones)

      // if (this.$can('update', 'libros')
      //   || this.$can('delete', 'libros')
      // ) {
      // }
    },
    cargarLibros() {
      this.fetchCursosPersonaRol().then(() => {
        if (typeof this.items[0].nombre_establecimiento !== 'undefined' ) {
          // mostrar columna establecimiento
          this.fields.push(
            {
              key: 'nombre_establecimiento',
              label: 'Establecimiento',
              sortable: true,
              thStyle: {
                width: '200px !important',
                display: 'table-cell',
                'vertical-align': 'middle',
              },
            },
          )
        }

        this.setTableList()
      })
    },
    addLibro() {
      this.$router.replace({
        name: 'libro',
      })
    },
    goToOpen(libro) {
      this.setLibro(libro)
      this.$router.push({
        name: 'libro',
        params: { id: libro.id },
      })
    },
    updateLibro(libro) {
      this.$swal({
        title: 'Actualizar libro!',
        html: 'Estás seguro que deseas actualizar el libro activo del'
          + ' libro<br><span class="font-weight-bolder">'
          + `${libro.nombre}</span>?`,
        footer: '<div class="text-center text-primary">Al actualizar el'
          + ' libro activo, se creará un nuevo marco de trabajo para el'
          + ' libro. No se puede devolver al libro anterior.</div>',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si, actualízalo!',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        this.overlay = true
        if (result.value) {
          // this.updateLibroLibro(libro).then(() => {
          //   this.$swal({
          //     icon: 'success',
          //     title: 'Libro activo actualizado!',
          //     html:
          //       'El libro activo del libro<br>'
          //       + ' <span class="font-weight-bolder">'
          //       + `${libro.nombre}</span>`
          //       + '<br>ha sido actualizado con éxito!',
          //     customClass: {
          //       confirmButton: 'btn btn-primary',
          //     },
          //   })
          //   this.overlay = false
          //   this.cargarLibros()
          // })
        } else {
          this.overlay = false
          this.cargarLibros()
        }
      })
    },
    remove(libro) {
      this.$swal({
        title: 'Eliminar libro!',
        text: `Estás seguro que deseas eliminar el libro
          "${libro.nombre}"?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si, eliminalo!',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        this.overlay = true
        if (result.value) {
          // this.removeLibros(libro.id).then(() => {
          //   this.$swal({
          //     icon: 'success',
          //     title: 'Eliminada con éxito!',
          //     text: `"${libro.nombre}" ha sido eliminada!`,
          //     customClass: {
          //       confirmButton: 'btn btn-success',
          //     },
          //   })
          //   this.overlay = false
          // })
        } else {
          this.overlay = false
        }
      })
    },

    // Vuexy
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of
      // buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}
</script>

<style lang="css">
  .text-center {
    text-align: center;
  }
</style>
